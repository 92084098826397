const ECReducer = (state, action) => {

  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      }
    case 'SET_OFF_LOADING':
      return {
        ...state,
        loading: false,
      }

    case 'SET_USER_AUTHORIZED':
      return {
        ...state,
        isUserAuthorized: true,
      }
    case 'SET_INSPECTIONS':
      return {
        ...state,
        inspections: action.payload,
        loading: false
      }
    case 'SET_INSPECTION_PHOTOS':
      return {
        ...state,
        inspections: action.payload.inspections,
        inspections2: action.payload.inspections2,
        loading: false
      }



    case 'SAVE_REQUEST':
      return {
        ...state,
        saveRequest: true,
        isAutomaticSaving: action.payload
      }
    case 'DROP_SAVE_REQUEST':
      return {
        ...state,
        saveRequest: false,
        isAutomaticSaving: false
      }

    case 'SET_GENERAL_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagGeneralReadyToSave: true
      }
    case 'DROP_GENERAL_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagGeneralReadyToSave: false
      }

    case 'SET_JOBSITE_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagJobsiteReadyToSave: true
      }
    case 'DROP_JOBSITE_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagJobsiteReadyToSave: false
      }

    case 'SET_ELEVATIOINS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagElevationsReadyToSave: true
      }
    case 'DROP_ELEVATIONS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagElevationsReadyToSave: false
      }

    case 'SET_SIDING_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagSidingReadyToSave: true
      }
    case 'DROP_SIDING_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagSidingReadyToSave: false
      }

    case 'SET_WINDOWS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagWindowsReadyToSave: true
      }
    case 'DROP_WINDOWS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagWindowsReadyToSave: false
      }
    case 'SET_GUTTERS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagGuttersReadyToSave: true
      }
    case 'DROP_GUTTERS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagGuttersReadyToSave: false
      }

    case 'SET_INTERIOR_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagInteriorReadyToSave: true
      }
    case 'DROP_INTERIOR_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagInteriorReadyToSave: false
      }
    case 'SET_ROOFFIELD_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagRooffieldReadyToSave: true
      }
    case 'DROP_ROOFFIELD_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagRooffieldReadyToSave: false
      }
    case 'SET_VENTILATION_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagVentilationReadyToSave: true
      }
    case 'DROP_VENTILATION_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagVentilationReadyToSave: false
      }
    case 'SET_TESTSQUARE_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagTestSquareReadyToSave: true
      }
    case 'DROP_TESTSQUARE_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagTestSquareReadyToSave: false
      }
    case 'SET_ROOFPHOTOS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagRoofPhotosReadyToSave: true
      }
    case 'DROP_ROOFPHOTOS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagRoofPhotosReadyToSave: false
      }
    case 'SET_SKYLIGHTS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagSkylightsReadyToSave: true
      }
    case 'DROP_SKYLIGHTS_READY_TO_SAVE_FLAG':
      return {
        ...state,
        flagSkylightsReadyToSave: false
      }




    case 'SET_UPDATE_PHOTO_FOR_GENERAL':
      return {
        ...state,
        flagNeedToUpdatePhotosForGeneralBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_GENERAL':
      return {
        ...state,
        flagNeedToUpdatePhotosForGeneralBlock: false
      }

    case 'SET_UPDATE_PHOTO_FOR_JOBSITE':
      return {
        ...state,
        flagNeedToUpdatePhotosForJobsiteBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_JOBSITE':
      return {
        ...state,
        flagNeedToUpdatePhotosForJobsiteBlock: false
      }

    case 'SET_UPDATE_PHOTO_FOR_ELEVATIONS':
      return {
        ...state,
        flagNeedToUpdatePhotosForElevationsBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_ELEVATIONS':
      return {
        ...state,
        flagNeedToUpdatePhotosForElevationsBlock: false
      }

    case 'SET_UPDATE_PHOTO_FOR_INTERIOR':
      return {
        ...state,
        flagNeedToUpdatePhotosForInteriorBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_INTERIOR':
      return {
        ...state,
        flagNeedToUpdatePhotosForInteriorBlock: false
      }

    case 'SET_UPDATE_PHOTO_FOR_ROOFFIELD':
      return {
        ...state,
        flagNeedToUpdatePhotosForRoofFieldBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_ROOFFIELD':
      return {
        ...state,
        flagNeedToUpdatePhotosForRoofFieldBlock: false
      }

    case 'SET_UPDATE_PHOTO_FOR_VENTILATION':
      return {
        ...state,
        flagNeedToUpdatePhotosForVentilationBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_VENTILATION':
      return {
        ...state,
        flagNeedToUpdatePhotosForVentilationBlock: false
      }
    case 'SET_UPDATE_PHOTO_FOR_TESTSQUARE':
      return {
        ...state,
        flagNeedToUpdatePhotosForTestSquareBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_TESTSQUARE':
      return {
        ...state,
        flagNeedToUpdatePhotosForTestSquareBlock: false
      }
    case 'SET_UPDATE_PHOTO_FOR_ROOFPHOTOS':
      return {
        ...state,
        flagNeedToUpdatePhotosForRoofPhotosBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_ROOFPHOTOS':
      return {
        ...state,
        flagNeedToUpdatePhotosForRoofPhotosBlock: false
      }

    case 'SET_UPDATE_PHOTO_FOR_WINDOWS':
      return {
        ...state,
        flagNeedToUpdatePhotosForWindowsBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_WINDOWS':
      return {
        ...state,
        flagNeedToUpdatePhotosForWindowsBlock: false
      }
    case 'SET_UPDATE_PHOTO_FOR_GUTTERS':
      return {
        ...state,
        flagNeedToUpdatePhotosForGuttersBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_GUTTERS':
      return {
        ...state,
        flagNeedToUpdatePhotosForGuttersBlock: false
      }
    case 'SET_UPDATE_PHOTO_FOR_SIDING':
      return {
        ...state,
        flagNeedToUpdatePhotosForSidingBlock: true
      }
    case 'DROP_UPDATE_PHOTO_FOR_SIDING':
      return {
        ...state,
        flagNeedToUpdatePhotosForSidingBlock: false
      }




    case 'CLEAN_CONTROLS_DATA':
      return {
        ...state,
        flagCleanAllControlsData: !state.flagCleanAllControlsData,
        readyGENERALdata: {},
        readyJOBSITEdata: {},
        readySIDINGdata: {},
        readyWINDOWSdata: {},
        readyGUTTERSdata: {},
        readyELEVATIONSdata: {},
        readyINTERIORdata: {},
        readyROOFFIELDdata: {},
        readyVENTILATIONdata: {},
        readyTESTSQUAREdata: {},
        readyROOFPHOTOSdata: {},
        readySKYLIGHTSdata: {},

      }

    case 'RELOAD_DATA':
      return {
        ...state,
        inspectionAllData: action.payload,
        flagReloadDatafromInspectionAllData: !state.flagReloadDatafromInspectionAllData,
      }


    case 'REFRESH_INSPECTIONS_LIST':
      return {
        ...state,
        flagNeedToRefreshInspectionsList: !state.flagNeedToRefreshInspectionsList,
      }



    case 'SET_SHOW_CAMERA':
      return {
        ...state,
        showCamera: true,
        showCameraFor: action.payload
      }
    case 'SET_SHOW_CAMERA_OFF':
      return {
        ...state,
        showCamera: false,
        //showCameraFor: {}
      }



    case 'SET_SHOW_NEW_INSPECTION_DIALOG':
      return {
        ...state,
        showInspectionDialog: true,
        inspectionAllData: action.payload
      }



    case 'SET_INSPECTION_ALL_DATA':
      return {
        ...state,
        inspectionAllData: action.payload
      }


    case 'UPDATE_INSPECTION_ALL_DATA_WITH_PHOTO':
      return {
        ...state,
        inspectionAllData: action.payload,
        flagNeedToUpdatePhotosForGeneralBlock: true
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_PHOTO_JOBSITE':
      return {
        ...state,
        inspectionAllData: action.payload,
        flagNeedToUpdatePhotosForJobsiteBlock: true
      }


    case 'UPDATE_INSPECTION_ALL_DATA_WITH_GENERAL_BLOCK':
      return {
        ...state,
        readyGENERALdata: action.payload
      }

    case 'UPDATE_INSPECTION_ALL_DATA_WITH_JOBSITE_BLOCK':
      return {
        ...state,
        readyJOBSITEdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_ELEVATIONS_BLOCK':
      return {
        ...state,
        readyELEVATIONSdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_SIDING_BLOCK':
      return {
        ...state,
        readySIDINGdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_WINDOWS_BLOCK':
      return {
        ...state,
        readyWINDOWSdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_GUTTERS_BLOCK':
      return {
        ...state,
        readyGUTTERSdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_INTERIOR_BLOCK':
      return {
        ...state,
        readyINTERIORdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_ROOFFIELD_BLOCK':
      return {
        ...state,
        readyROOFFIELDdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_VENTILATION_BLOCK':
      return {
        ...state,
        readyVENTILATIONdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_TESTSQUARE_BLOCK':
      return {
        ...state,
        readyTESTSQUAREdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_ROOFPHOTOS_BLOCK':
      return {
        ...state,
        readyROOFPHOTOSdata: action.payload
      }
    case 'UPDATE_INSPECTION_ALL_DATA_WITH_SKYLIGHTS_BLOCK':
      return {
        ...state,
        readySKYLIGHTSdata: action.payload
      }



    case 'UPDATE_INSPECTION_ALL_DATA':
      return {
        ...state,
        inspectionAllData: action.payload
      }



    case 'SET_SHOW_INSPECTION_DIALOG':
      return {
        ...state,
        editInspectionId: action.payload,
        showInspectionDialog: true
      }


    case 'SET_HIDE_INSPECTION_DIALOG':
      return {
        ...state,
        showInspectionDialog: false,
        photoOfFrontOfProperty: {DETAIL_URL: '', FILE_ID: '', ID: ''}
      }
    case 'SET_INSPECTION_GENERAL_DATA':
      return {
        ...state,
        inspectionGeneralBlockInfo: action.payload
      }
    case 'SET_INSPECTION_JOBSITE_DATA':
      return {
        ...state,
        inspectionJobsiteBlockInfo: action.payload
      }
    case 'SET_INSPECTION_ELEVATIONS_DATA':
      return {
        ...state,
        inspectionElevationsBlockInfo: action.payload
      }
    case 'SET_INSPECTION_ELEVATIONS_SUB_DATA':
      return {
        ...state,
        inspectionElevationsSUBBlockInfo: action.payload
      }
    case 'SET_INSPECTION_ROOF_DATA':
      return {
        ...state,
        inspectionRoofBlockInfo: action.payload
      }
    case 'SET_INSPECTION_ROOF_SUB_DATA':
      return {
        ...state,
        inspectionRoofSUBBlockInfo: action.payload
      }
    case 'SET_INSPECTION_SIDING_DATA':
      return {
        ...state,
        inspectionSidingBlockInfo: action.payload
      }
    case 'SET_INSPECTION_DAMAGE_DATA':
      return {
        ...state,
        inspectionDamageBlockInfo: action.payload
      }





    case 'SET_INSPECTION_WINDOWS_DATA':
      return {
        ...state,
        inspectionWindowsBlockInfo: action.payload
      }
    case 'SET_INSPECTION_GUTTERS_DATA':
      return {
        ...state,
        inspectionGuttersBlockInfo: action.payload
      }
    case 'SET_INSPECTION_INTERIOR_DATA':
      return {
        ...state,
        inspectionInteriorBlockInfo: action.payload
      }
    case 'SET_INSPECTION_PHOTOS_DATA':
      return {
        ...state,
        inspectionPhotosBlockInfo: action.payload
      }
    case 'SET_INSPECTION_REPORTS_DATA':
      return {
        ...state,
        inspectionReportsBlockInfo: action.payload
      }


    case 'SET_B24_ACCESS_TOKEN':
      return {
        ...state,
        access_token_data: action.payload
      }


    case 'CLEAR_PHOTOS_ELEVATION':
      return {
        ...state,
        inspectionAllData: action.payload
      }
    case 'SET_B24_JS_INIT_FINISH':
      return {
        ...state,
        B24jsIntializationFinished: true,
        inspectionReportsBlockInfo: action.payload
      }











    default:
      return state
  }
}

export default ECReducer
