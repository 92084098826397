import axios from 'axios'

const B24_URL = process.env.REACT_APP_B24_URL
//const B24_TOKEN = process.env.REACT_APP_B24_TOKEN

const github = axios.create({
  baseURL: B24_URL,
/*  headers: { Authorization: `token ${B24_TOKEN}` },*/
})

export const getB24accessToken = async (code) => {


  console.log('getting access token')

  const inputData = {
    // email: email,
    // answers: JSON.stringify(answers)
    code: code
  }

  // we need this proxy script, cause it's impssible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded.blueocean24.ru/get_b24_access_token.php'
  const response = await fetch(url, {
    method: 'POST',         // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache',      // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow',     // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(inputData) // body data type must match "Content-Type" header
  });

  //let response = await fetch(url,{mode: 'no-cors'});

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
                     // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    const userInfo = await getUserInfo(json, json.user_id)
    const inspectorName = `${userInfo[0].NAME} ${userInfo[0].LAST_NAME}`

    json = {...json, inspectorName: inspectorName}

    console.log("Response getB24accessToken() result (with InspectorName):")
    console.log(json)


    return json

  } else {
    console.log("Ошибка HTTP: " + response.status);
  }



  //let url = `https://estateclaim.bitrix24.com/oauth/authorize/?client_id=local.63f66464884702.74717056`
/*  let url = `https://oauth.bitrix.info/oauth/token/?grant_type=authorization_code&client_id=local.63f66464884702.74717056&client_secret=p3bmuBC2j2a9GCQc9q33ypRRGtdciRSuau8eGRAT02IMuvDTle&code=${code}`

  const response = await github.get(url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      //"Access-Control-Allow-Origin": "https://inspections-embedded.blueocean24.ru/",
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Credentials': 'true'

    },
    responseType: "json",
  })


console.log('response1: ')
console.log(response)*/

}

export const getDealsTest = async (b24_access_token_data, params) => {

/*
  "access_token": "s1morf609228iwyjjpvfv6wsvuja4p8u",
     "client_endpoint": "https://portal.bitrix24.com/rest/",
     "domain": "oauth.bitrix.info",
     "expires_in": 3600,
     "member_id": "a223c6b3710f85df22e9377d6c4f7553",
     "refresh_token": "4f9k4jpmg13usmybzuqknt2v9fh0q6rl",
     "scope": "app",
     "server_endpoint": "https://oauth.bitrix.info/rest/",
     "status": "T"
*/


  const inputData = {
    // email: email,
    // answers: JSON.stringify(answers)
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',

    // auth: auth,
    // function: 'crm.deal.list.json',
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result (test deal.list):")
    console.log(json)

    return json

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const _getInspections = async (b24_access_token_data, params = {}) => {

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result (test INSPECTIONS.list):")
    console.log(json)

    return json

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const runB24apiMethod = async (b24_access_token_data, method, params = {}) => {

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: method,
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

console.log("Response result from runB24apiMethod():")
console.log(json)

    return json

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}


export const saveGeneralBlock = async (b24_access_token_data, paramsIn) => {


  let params = {
    IBLOCK_TYPE_ID: 'lists',
    IBLOCK_ID: '101',
    ELEMENT_CODE: 'element_' + Math.floor(new Date().getTime() / 1000),
    //ELEMENT_CODE: 'element_22',
    FIELDS: {
      ...paramsIn
    }

  }

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "lists.element.add",
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result from runB24apiMethod():")
    console.log(json)

    return json

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const savePhotoIntoFolder = async (b24_access_token_data, paramsIn) => {


  let params = {
      ...paramsIn
  }

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "disk.folder.uploadfile",
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result from runB24apiMethod():")
    console.log(json)

    //return json
    return {FILE_ID: json.result.FILE_ID, ID: json.result.ID, DETAIL_URL: json.result.DOWNLOAD_URL, IS_NEW: true}

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const createNewInspectionAndPrepareData = async (b24_access_token_data, paramsIn = null) => {

  let params = {}

  if(paramsIn == null)
  {
    params = {
      IBLOCK_TYPE_ID: 'lists',
      IBLOCK_ID: '101',
      ELEMENT_CODE: 'element_' + Math.floor(new Date().getTime() / 1000),
      FIELDS: {
          ...paramsIn,
          NAME: 'element_' + Math.floor(new Date().getTime() / 1000),
          // 'PROPERTY_1111': b24_access_token_data.user_id,
          // 'PROPERTY_807': b24_access_token_data.inspectorName,


      }
    }
  }
  else {
    params = {
      IBLOCK_TYPE_ID: 'lists',
      IBLOCK_ID: '101',
      ELEMENT_CODE: 'element_' + Math.floor(new Date().getTime() / 1000),
      FIELDS: {
        ...paramsIn,
        // 'PROPERTY_1111': b24_access_token_data.user_id,
        // 'PROPERTY_807': b24_access_token_data.inspectorName,
      }
    }

  }

  console.log("params from createNewInspectionAndPrepareData() lists.element.add:")
  console.log(params)


  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "lists.element.add",
    params: params
  }

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result from lists.element.add:")
    console.log(json)


    const createFolder = async (b24_access_token_data, inspectionId) => {

      let params = {
        id: 428223,
        data: {
          NAME: inspectionId
        }
      }

      const inputData = {
        AUTH_ID: b24_access_token_data.access_token,
        DOMAIN: 'oauth.bitrix.info',
        REFRESH_ID: b24_access_token_data.refresh_token,
        APP_SID: 'local.641421da57ec34.12856872',
        method: "disk.folder.addsubfolder",
        params: params
      }

      const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
      const response = await fetch(url, {
        method: 'POST', cache: 'no-cache', credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*"
        }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
      });

      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        let json = await response.json();

        console.log("Response result from creating folder:")
        console.log(json)

        return json.result.ID
      }
      else {
        console.log("Ошибка HTTP: " + response.status);
      }
    }

    const newInspectionId = json.result
    const newInspectionFolderId = await createFolder(b24_access_token_data, newInspectionId)

    return { newInspectionId: newInspectionId, newInspectionFolderId: newInspectionFolderId }

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }
}

export const updateInspection = async (b24_access_token_data, paramsIn) => {


  let params = {
    IBLOCK_TYPE_ID: 'lists',
    IBLOCK_ID: '101',
    ELEMENT_ID: paramsIn.inspectionId,
    //ELEMENT_CODE: 'element_' + Math.floor(new Date().getTime() / 1000),
    //ELEMENT_CODE: 'element_22',
    FIELDS: {
      ...paramsIn,
    }

  }

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "lists.element.update",
    params: params
  }

  console.log('>>>>>>>>>>>> Update inspection with PARAMS: for (lists.element.update)')
  console.log(params)

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    // console.log("Response result from runB24apiMethod(), lists.element.edit :")
    // console.log(json)


    return json

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const getDealList = async (b24_access_token_data) => {


  let params = {
    order: { "DATE_MODIFY": "DESC", "STAGE_ID": "ASC" },
    filter: {
      //"CATEGORY_ID": 0,
      "CLOSED": 'N' },
    select: [ "ID", "TITLE"/*, "STAGE_ID", "CATEGORY_ID"*/ ]
  }
  //filter: { "CATEGORY_ID": 0, "ASSIGNED_BY_ID":  b24_access_token_data.user_id, "CLOSED": 'N' },

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "crm.deal.list",
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    // console.log("Response result from runB24apiMethod(), lists.element.edit :")
    // console.log(json)

    return json.result

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const createNewDeal = async (b24_access_token_data, newDealParams) => {


  let params = {
    fields: {"TITLE": newDealParams.TITLE},
    params: { "REGISTER_SONET_EVENT": "Y", "CATEGORY_ID": 0, "STAGE_ID": "NEW" }
  }

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "crm.deal.add",
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result from runB24apiMethod(), crm.deal.add :")
    console.log(json)

    return json.result

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const getInspectionById = async (b24_access_token_data, inspectionId) => {

  let params = {
    'IBLOCK_TYPE_ID': 'lists',
    'IBLOCK_ID': '101',
    'FILTER': {
      '=ID': inspectionId
    }
  }

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "lists.element.get",
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log(" >>>>>>>>>> Response result from runB24apiMethod(), lists.element.get (возврат inspection по ID) :")
    console.log(json)

    return json.result[0]

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const getImageUrl = async (b24_access_token_data, fileId = '') => {

  let params = {
    id: fileId
  }

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "disk.file.get",
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

console.log("Response result from getImageUrl(), disk.file.get:")
console.log(json)

    return json.result

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}


export const getLinkedInspections =  async (b24_access_token_data, mainInspectionId) => { //Structures that are linked to the main house

  let params = {
    'IBLOCK_TYPE_ID': 'lists',
    'IBLOCK_ID': '101',
    'FILTER': {
      PROPERTY_795: 191, // только 'additional structures'
      PROPERTY_797: mainInspectionId //_Linked Main Structure
    }
  }

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "lists.element.get",
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    // console.log("Response result from runB24apiMethod(), lists.element.edit :")
    // console.log(json)

    return json.result

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}


export const proxyCreateReport =  async (b24_access_token_data, inParams) => { //Structures that are linked to the main house

  let params = inParams

  const inputData = {
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded.blueocean24.ru/pdf_create_report.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result from pdf_create_report.php():")
    console.log(json)

    return json.result

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const proxyGetPDFReport =  async (b24_access_token_data, inParams) => {

  let params = inParams

  const inputData = {
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded.blueocean24.ru/pdf_get_created_pdf.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result from pdf_get_created_pdf.php:")
    console.log(json)

    return json.result

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}

export const proxySendReportToClient =  async (b24_access_token_data, inParams) => {

  let params = inParams

  const inputData = {
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded.blueocean24.ru/pdf_send_email.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result from pdf_send_email.php:")
    console.log(json)

    return json.result

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}


export const getUserInfo =  async (b24_access_token_data, userId) => {

  let params = {
    'ID': userId,
  }

  const inputData = {
    AUTH_ID: b24_access_token_data.access_token,
    DOMAIN: 'oauth.bitrix.info',
    REFRESH_ID: b24_access_token_data.refresh_token,
    APP_SID: 'local.641421da57ec34.12856872',
    method: "user.get",
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/b24_api_call.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result from runB24apiMethod(), user.get :")
    console.log(json)

    return json.result

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}


export const getArchiveLink =  async (photosData, inspectionID) => {

  let params = {
    'photos': photosData,
    'inspectionID': inspectionID
  }

  const inputData = {
    params: params
  }

  // we need this proxy script, because it's impossible to invoke B24 API call right from here.

  const url = 'https://inspections-embedded-photos.blueocean24.ru/archive_photos.php'
  const response = await fetch(url, {
    method: 'POST', cache: 'no-cache', credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*"
    }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
  });

  if (response.ok) { // если HTTP-статус в диапазоне 200-299
    // получаем тело ответа (см. про этот метод ниже)
    let json = await response.json();

    console.log("Response result from getArchiveLink() :")
    console.log(json)

    return json.result

  }
  else {
    console.log("Ошибка HTTP: " + response.status);
  }

}