import * as React from 'react';
import {useContext, useEffect} from "react";
import ECContext from "../context/inspections/ECContext";
import {
   getArchiveLink, getImageUrl, getB24accessToken, getDealsTest, getInspectionsList
} from '../context/inspections/ECActions'
import Spinner from './layout/Spinner'
import {Button, ImageList, ImageListItem, Stack} from "@mui/material";
import Image from 'material-ui-image'
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";

import SaveIcon from '@mui/icons-material/Save';
import {LoadingButton} from "@mui/lab";


export default function PhotosList() {

   const { dispatch, inspections, inspections2, access_token_data, loading } = useContext(ECContext)


   const [photosData, setPhotosData] = React.useState([]);
   const [showList, setShowList] = React.useState(true);
   const [bigImageSrc, setBigImageSrc] = React.useState('');

   const [loadingPhotos, setLoadingPhotos] = React.useState(false);

   const itemData = [
      {
         img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
         title: 'Breakfast',
      },
      {
         img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
         title: 'Burger',
      },
      {
         img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
         title: 'Camera',
      },
      {
         img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
         title: 'Coffee',
      },
      {
         img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
         title: 'Hats',
      },
      {
         img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
         title: 'Honey',
      },
      {
         img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
         title: 'Basketball',
      },
      {
         img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
         title: 'Fern',
      },
      {
         img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
         title: 'Mushrooms',
      },
      {
         img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
         title: 'Tomato basil',
      },
      {
         img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
         title: 'Sea star',
      },
      {
         img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
         title: 'Bike',
      },
   ];



   useEffect(() => {

      if(inspections.length == 0)
         return;

     dispatch({ type: 'SET_LOADING' })

     const inspectionData = inspections['items'][0];

      async function getPhotos() {

         const photosData1 = await getInspectionPhotos(inspectionData['id']);

//console.log('Photos from get_inspection_photos.php', photosData1);

         if(photosData1.length == 0) return;

         window.curImage = 0
         window.totalImages = photosData1.length
         window.photos1 = []

         const tempData = [];
         for (let index in photosData1) {
            tempData.push(photosData1[index])
         }

//         console.log('tempData', tempData);

         dispatch({ type: 'SET_OFF_LOADING' })
         setPhotosData(tempData);
      }
      getPhotos()






   }, [inspections]);

   //console.log('loading: ',loading);

   if (!loading) {

      // console.log('loading: ',loading);
      // console.log('photosData (redraw): ',photosData);

      return (

         <>
            <ImageList cols={5} rowHeight={100} sx={{ display: showList !== true ? 'none' : '' }}>
               {photosData.map((item) => (
                     <ImageListItem key={item.ID}>
                        <img
                           src={`${item.DETAIL_URL}`}
                           srcSet={`${item.DETAIL_URL}`}
                           loading="lazy"
                           onClick={(event) => {
                              setBigImageSrc(event.target.src)
                              setShowList(false)
                           }}
                        />
                     </ImageListItem>
               ))}
            </ImageList>


                        <br/>

            <LoadingButton
               loading={loadingPhotos}
               loadingPosition="start"
               startIcon={<SaveIcon />}
               variant="outlined"

               onClick={ async () => {
                  console.info("Test");
                  setLoadingPhotos(true)

                  const archiveLink = await getArchiveLink(photosData, inspections['items'][0]['id'])

                  //console.log('zipLink: ', archiveLink.zipFile)

                  var file_path = archiveLink.zipFile
                  var a = document.createElement('A');
                  a.href = file_path;
                  a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);

                  setLoadingPhotos(false)
               }}
            >
               Download all files as archive
            </LoadingButton>




{/*            <Link
               component="button"
               variant="body2"
               onClick={ async () => {
                  console.info("Test");

                  const archiveLink = await getArchiveLink(photosData)

                  console.log('archive link from the server: ', archiveLink)

               }}
            >
               Download all files as archive
            </Link>*/}






            <Box sx={{ display: showList === false ? 'flex' : 'none', width:'100%', height: '100vh' }}>
               <img
                  src={bigImageSrc}
                  onClick={() => setShowList(true) }
                  alt={bigImageSrc}
               />
            </Box>


         </>
      );
   }
   else {
      return <Spinner />
   }
}


async function getInspectionPhotos(inspectionId) {

   const inputData = {
      inspectionId: inspectionId
   }

   const url = 'https://inspections-embedded-photos.blueocean24.ru/get_inspection_photos.php'
   const response = await fetch(url, {
      method: 'POST', cache: 'no-cache', credentials: 'include',
      headers: {
         'Content-Type': 'application/json',
         "Access-Control-Allow-Origin": "*"
      }, redirect: 'follow', referrerPolicy: 'no-referrer', body: JSON.stringify(inputData)
   });

   if (response.ok) {
      let json = await response.json();
      return json
   }
   else {
      console.log("Ошибка HTTP (get_inspection_photos.php): " + response.status);
      return {};
   }
}