//import spinner from './assets/spinner.gif'
import spinner from './assets/spinner_with_logo.gif'
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {Stack} from "@mui/material";

function Spinner() {
  return (
     <>

{/*
    <Box sx={{my: 'auto', mx: 'auto', textAlign: 'center', width: '100%', mt: '2', height: '100vh' }} justifyContent='center' >
       <img
          width={100}
          src={spinner}
          alt='Loading...'
       />
    </Box>
*/}


        <Stack direction="row" width='100%' height='100vh' alignItems="center" spacing={1} justifyContent="center" alignItems="center">
           <img
              width={150}
              src={spinner}
              alt='Loading...'
           />
        </Stack>




     </>
  )
}

export default Spinner
