import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import {useContext, useEffect} from "react";
import ECContext from "../context/inspections/ECContext";
import {getB24accessToken, getDealsTest, getInspections, runB24apiMethod} from "../context/inspections/ECActions";
import {useNavigate} from "react-router-dom";
import BXAPI from "../api.bitrix24";



const Search = styled('div')(({ theme }) => ({
   position: 'relative',
   borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha(theme.palette.common.white, 0.15),
   '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
   },
   marginLeft: 0,
   width: '100%',

}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
   padding: theme.spacing(0, 2),
   height: '100%',
   position: 'absolute',
   pointerEvents: 'none',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
   color: 'inherit',
   width: '100%',
   '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 1),
      // vertical padding + font size from searchIcon
      //paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      //transition: theme.transitions.create('width'),
      width: '100%',
   },
}));

export default function SearchAppBar() {


   const { B24placementInfo, B24jsIntializationFinished, dispatch, access_token_data, isUserAuthorized, flagNeedToRefreshInspectionsList } = useContext(ECContext)
   const navigate = useNavigate();
   const [searchText, setSearchText] = React.useState('');
   const [dealId, setDealId] = React.useState('');




   useEffect(() => {

      BXAPI();

      window.BX24.init(function(){

         const b24_access_token_data = window.BX24.getAuth()
//console.log('getAuth: ', b24_access_token_data)
         dispatch({ type: 'SET_B24_ACCESS_TOKEN', payload: b24_access_token_data })

         // console.log('Инициализация завершена!', window.BX24.isAdmin());
         // console.log('Placement info:', window.BX24.placement.info());


         // т.к. мы в Task сейчас, то пришел нам taskId. Надо по нему получить объект задачи и оттуда взять Id связанной сделки

         const taskId = window.BX24.placement.info().options.taskId.length > 0 ? window.BX24.placement.info().options.taskId : '-1'

         window.BX24.callMethod(
            'tasks.task.get',
            {taskId: taskId, select:['ID','TITLE', 'UF_CRM_TASK']},
            function(result)
            {
               if(result.error()) {
                  console.log("Error: " + result.error());
                  return
               }
               else {
                  //console.log('Result (tasks.task.get):',result.data());

                  const taskData = result.data().task
                  if(taskData.hasOwnProperty('ufCrmTask')) {
                     if(taskData.ufCrmTask.length > 0) {
                        const taskLinkedDealId = taskData.ufCrmTask[0].replace('D_', '')

                        //console.log('taskLinkedDealId', taskLinkedDealId)

                        const tmpDealId = taskLinkedDealId
                        //const tmpDealId = window.BX24.placement.info().options.ID.length > 0 ? window.BX24.placement.info().options.ID : '-1'
                        setDealId(tmpDealId)

                        dispatch({type: 'SET_B24_JS_INIT_FINISH', payload: {}})
                     }
                  }
               }
            }
         );





      });
   }, []);




   useEffect(() => {

//console.log('useEffect | B24jsIntializationFinished')

      dispatch({ type: 'SET_LOADING' })

      if(B24jsIntializationFinished !== true)
         return;

// console.log('passed finshing check')
// console.log('passed finshing check')


      async function getInspectionsFromB24(searchCriteria = '', _dealId) {

//         console.log('pacement info', window.BX24.placement.info())

            const params = {
               'entityTypeId': 144,
               'select': ['*', 'uf_*'],
               'filter': {
                  ufCrm7_1684660421: false, //$temporary не показываем
                  ufCrm7_1684774638: 2273, // Structure Type == Main Structure
                  parentId2: _dealId
               }
            };


//console.log('params:', params)

//console.log('>> before calling crm.item.list:')

            window.BX24.callMethod(
               'crm.item.list',
               params,
               function(result)
               {
                  if(result.error())
                     console.log("Error: " + result.error());
                  else {

// console.log('Result (inspection):: in SearchAppBar',result.data());
// console.log('>> Setting SET_INSPECTION_PHOTOS with result.data()')

                     dispatch({ type: 'SET_INSPECTION_PHOTOS',
                        payload: {
                           inspections: result.data(),
                           inspections2: [],
                        }
                        })
                  }
               }
            );
            // window.BX24.callMethod(
            //    'lists.element.get',
            //    params,
            //    function(result)
            //    {
            //       if(result.error())
            //          console.log("Error: " + result.error());
            //       else {
            //          console.log('Result (inspection):',result.data());
            //
            //          dispatch({ type: 'SET_INSPECTION_PHOTOS',
            //             payload: {
            //                inspections: result.data(),
            //                inspections2: [],
            //             }
            //             })
            //       }
            //    }
            // );


            //const inspections = await runB24apiMethod(access_token_data, "lists.element.get", params)
            //dispatch({ type: 'SET_INSPECTIONS', payload: inspections.result })


      }
      getInspectionsFromB24('', dealId)

      /*async function getAuthorization() {
         const params = new URLSearchParams(window.location.search);

         if(Object.keys(access_token_data).length === 0 && params.get("code") == null) {

            console.log('--- performing redirect to B24 OAuth server')
            window.location.href = 'https://estateclaim.bitrix24.com/oauth/authorize/?client_id=local.640ee256c19c14.58057769'
         }
         else if (Object.keys(access_token_data).length === 0 && params.get("code") != null) {

            console.log('--- Need to get access_token')
            console.log('--- current access_token_data: ')
            console.log(access_token_data)
            async function getToken(code) {
               const b24_access_token_data = await getB24accessToken(code)

               dispatch({ type: 'SET_B24_ACCESS_TOKEN', payload: b24_access_token_data })
               dispatch({ type: 'SET_USER_AUTHORIZED'})

               navigate('/')
            }
            await getToken(params.get('code'));
         }
      }*/
      //getAuthorization();

   }, [B24jsIntializationFinished]);



   useEffect(() => {

      getFilteredInspectionsFromB24()

   }, [flagNeedToRefreshInspectionsList]);



   const getFilteredInspectionsFromB24 =  async () => {

      if(isUserAuthorized === true) {
         // getting inspection element from B24 List
         console.log("Getting inspections using filter")

         const params = {
            'IBLOCK_TYPE_ID': 'lists',
            'IBLOCK_ID': '101',
            'FILTER': {
               PROPERTY_795: 189, // в списке инстпекций показываем только по главным строениям
               NAME: '%' + searchText + '%',
               PROPERTY_789: dealId
            }
         };

         const inspections = await runB24apiMethod(access_token_data, "lists.element.get", params)
         dispatch({ type: 'SET_INSPECTIONS', payload: inspections.result })

      }
   }

   const keyPress =  async (e) => {
      if(e.keyCode == 13){
         await getFilteredInspectionsFromB24(searchText)
      }
   }



   return (
      <></>
/*      <Box sx={{ flexGrow: 1 }}>
         <AppBar position="static">
            <Toolbar>
               <Search>
                  <StyledInputBase
                     placeholder="Inspections Search"
                     inputProps={{ 'aria-label': 'search' }}
                     value={searchText}
                     onChange={(event) => {
                        setSearchText(event.target.value)
                     }}
                     onKeyDown={keyPress}
                  />
               </Search>

               <IconButton type="button" sx={{ p: '10px' }} aria-label="search"
                  onClick={getFilteredInspectionsFromB24}
               >
                  <SearchIcon />
               </IconButton>


            </Toolbar>
         </AppBar>
      </Box>*/
   );
}