import * as React from 'react';

import SearchAppBar from "../components/SearchAppBar";
import PhotosList from "../components/PhotosList";


function Home() {


   return (
      <>
         <SearchAppBar/>
         <PhotosList/>


         {/*<AddNewIinspectionFAB/>*/}
      </>
  )
}

export default Home


